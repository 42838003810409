import './App.css';
import { Routes, Route } from 'react-router-dom';
import PageResetComp from './components/PageResetComp';
import DynamicCompLoader from './components/DynamicCompLoader';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';

function App() {
  return (
    <div className="App">
      <PageResetComp />
      <Routes>
        <Route exact path='/integrated-solar-roof' element={<DynamicCompLoader dynamicComp='./landing-pages/integrated-solar-roof/IntegratedSolarRoofComp' />} />
        <Route exact path='/integrated-solar-roof/success' element={<DynamicCompLoader dynamicComp='./landing-pages/integrated-solar-roof/Success' />} />
        <Route exact path='/integrated-solar-roof/error' element={<DynamicCompLoader dynamicComp='./landing-pages/integrated-solar-roof/Error' />} />
        
        <Route exact path="/" element={<DynamicCompLoader dynamicComp='./website/pages/Home' />} />
        <Route exact path='/about' element={<DynamicCompLoader dynamicComp='./website/pages/About' />} />
        <Route exact path='/portfolio' element={<DynamicCompLoader dynamicComp='./website/pages/Portfolio' />} />
        <Route exact path='/technical-specs/:id' element={<DynamicCompLoader dynamicComp='./website/pages/TechnicalSpecifications' />} />
        {/* <Route exact path='/technical-specs/atum-inroof-solar' element={<DynamicCompLoader dynamicComp='./website/pages/TechnicalSpecifications' />} />
        <Route exact path='/technical-specs/atum-conventional-solar-module' element={<DynamicCompLoader dynamicComp='./website/pages/TechnicalSpecifications' />} /> */}

        <Route exact path='/news' element={<DynamicCompLoader dynamicComp='./website/pages/NewsAndEvent' />} />
        <Route exact path='/blog' element={<DynamicCompLoader dynamicComp='./website/pages/Blog' />} />
        <Route exact path='/blog/:bdheading' element={<DynamicCompLoader dynamicComp='./website/pages/BlogDetails' />} />
        <Route exact path='/contact' element={<DynamicCompLoader dynamicComp='./website/pages/Contact' />} />
        <Route exact path='/terms-conditions' element={<DynamicCompLoader dynamicComp='./website/pages/TermsConditions' />} />
        <Route exact path='/privacy-policy' element={<DynamicCompLoader dynamicComp='./website/pages/PrivacyPolicy' />} />
        <Route exact path='/cookie-policy' element={<DynamicCompLoader dynamicComp='./website/pages/CookiePolicy' />} />
        <Route exact path='/solar-panels/:title' element={<DynamicCompLoader dynamicComp='./website/pages/SolarPanels' />} />
        <Route exact path='/success' element={<DynamicCompLoader dynamicComp='./website/pages/Success' />} />
        <Route exact path='/error' element={<DynamicCompLoader dynamicComp='./website/pages/Error' />} />
      </Routes>
    </div>
  );
}

export default App;