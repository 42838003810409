import React from 'react';
import Header from './components/website/common/Header';
import Footer from './components/website/common/Footer';
import { Link } from 'react-router-dom';

export const ErrorPage = (props) => {
  return (
    <div>
        <Header/>
            <section class="py-5">
                <div class="container py-4 my-5">
                    <div class="row">
                        <div class="col-12 text-center py-4 my-5">
                            <h1 class="primary-text text-capitalize all-heading-text m-0 p-0 fs-1 fw-bolder"><b>{props.title}</b></h1>
                            <p class="text-muted m-0 p-0">{props.desc}</p>
                            <Link to={props.path} class="btn btn-danger rounded-2 mt-3">{props.pathName}</Link>
                        </div>
                    </div>
                </div>
            </section>
        <Footer/>
    </div>
  )
}
